// globals
window.addEventListener("DOMContentLoaded", () => {
  class Accordion {
    constructor(AcBtns, AcDatas){
      this.isResize = true;
      this.w = window.innerWidth;
      this.HeightArray = [];
      this.AcBtns = AcBtns;
      this.AcDatas = AcDatas;

      this.onSwitch = this.onSwitch.bind(this);
      this.init();
    }

    setStyle(){
      this.AcDatas.forEach(ele => {
        let h = ele.children[0].clientHeight
        ele.style.height = 0;

        this.HeightArray.push(h);
      });
    }

    onAllSwitch(){
      this.AcBtns.forEach(ele => {
        var parent = ele.parentNode;
        var next = ele.nextElementSibling;
        if (parent.classList.contains("-open")) {
          next.style.height = 0;
          parent.classList.remove("-open");
        }
      });
    }

    onSwitch(ele, i){
      var parent = ele.parentNode;
      var next = ele.nextElementSibling;

      if (parent.classList.contains("-open")) {
        this.onAllSwitch();
        next.style.height = 0;
        parent.classList.remove("-open");
      } else {
        this.onAllSwitch();
        parent.classList.add("-open");
        next.style.height = this.HeightArray[i] + "px";
      }
    }

    onResize(){
      this.HeightArray.length = 0;

      this.AcDatas.forEach(ele => {
        let h = ele.children[0].clientHeight
        if (ele.parentNode.classList.contains("-open")) ele.style.height = h + "px";
        this.HeightArray.push(h);
      });
      this.w = window.innerWidth;
    }

    init(){
      this.setStyle();

      window.addEventListener("resize", e => {
        if (this.isResize) {
          requestAnimationFrame(() => {
            this.isResize = true;
            if (this.w != window.innerWidth) {
              this.onResize();
            };
          });
          this.isResize = false;
        };
      });

      this.AcBtns.forEach((ele, i) => {
        ele.addEventListener("click", e => {
          this.onSwitch(ele, i);
        });
      });
    }
  }

  const AcBtns = document.querySelectorAll(".q-ac-btn");
  const AcDatas = document.querySelectorAll(".q-ac-data")
  new Accordion(AcBtns, AcDatas);
});
var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
class Header {
  constructor(el, opt){
    this.event();
  }
  event() {
    $('.js-drawer').on({
      click: function() {
        $('.js-header').toggleClass('is-acitve');
        $('html').toggleClass('no-scroll');
      }
    })
    // let hList = document.querySelectorAll('.js-hList a');
    // let hList_num = 0;
    
    // hList.forEach(el => {
    //   if(el.getAttribute('href').indexOf(location.pathname.split('/')[3]) > 0) {
    //     el.parentNode.classList.add('is-active');
    //     hList_num++;
    //   }
    // });
    // if(!hList_num) {
    //   hList[0].parentNode.classList.add('is-active');
    // }
  }
};
class HeaderScroll {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(function(){
      var headerHeight = $('.js-header').outerHeight(); // ヘッダーについているID、クラス名など、余白を開けたい場合は + 10を追記する。
      var urlHash = location.hash; // ハッシュ値があればページ内スクロール
      if(urlHash) { // 外部リンクからのクリック時
        $('body,html').stop().scrollTop(0); // スクロールを0に戻す
        setTimeout(function(){ // ロード時の処理を待ち、時間差でスクロール実行
          var target = $(urlHash);
          var position = target.offset().top - headerHeight;
          $('body,html').stop().animate({scrollTop:position}, 500); // スクロール速度ミリ秒
        }, 100);
      }
      $('a[href^="#"]').click(function(){ // 通常のクリック時
        var href= $(this).attr("href"); // ページ内リンク先を取得
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, 500); // スクロール速度ミリ秒
        return false; // #付与なし、付与したい場合は、true
      });
    });
  }
}

class InviewEvent {
  constructor(el, opt){
    this.el = el;
    this.all = this.el + '-all';
    this.eachUp = this.el + '-each';
    this.eachItem = this.el + '-eachItem';
    this.eachItemFast = this.el + '-eachItem-fast';
    this.eachItemDelay = this.el + '-eachItem-delay';
    this.event();
  }
  event() {
    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }
    let optionsAll = {
      threshold: [0, 0.9, 1]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          if(item.classList.contains('js-inview-lottie')){
            this.inviewLottie(item);
          }
          observerEnter.unobserve(item);
        }
      });
    }, options);
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.9){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          observerAll.unobserve(item);
        } else {
          item.classList.remove('is-inview');
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    const inviewAllItem = document.querySelectorAll(this.all);
    inviewItem.forEach((e)=>{
      observerEnter.observe(e);
    });
    inviewAllItem.forEach((e)=>{
      observerAll.observe(e);
    });
  }
  inviewEachUp(item){
    $(item).find(this.eachItem).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.2 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemFast).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.1 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemDelay).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.5 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
  }
  inviewLottie(item){
    if($(item).hasClass('js-lottie-flow')) {
      lottie.flow();
    }
    if($(item).hasClass('js-lottie-no01')) {
      lottie.no01();
    }
    if($(item).hasClass('js-lottie-no02')) {
      lottie.no02();
    }
    if($(item).hasClass('js-lottie-no03')) {
      lottie.no03();
    }
    if($(item).hasClass('js-lottie-no04')) {
      lottie.no04();
    }
    if($(item).hasClass('js-lottie-no05')) {
      lottie.no05();
    }
    if($(item).hasClass('js-lottie-no06')) {
      lottie.no06();
    }
    if($(item).hasClass('js-lottie-no07')) {
      lottie.no07();
    }
  }
}

class MegaMenu {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    $(function() {

      // ①マウスをボタンに乗せた際のイベントを設定
      $('.mega-hov').hover(function() {


        // ②乗せたボタンに連動したメガメニューをスライドで表示させる
        $(this).find('.menu-contents').stop().fadeIn();

      // ③マウスをボタンから離した際のイベントを設定
      }, function() {

        // ④マウスを離したらメガメニューをスライドで非表示にする
        $(this).find('.menu-contents').stop().fadeOut();

      });

    });

  }
}

window.addEventListener('DOMContentLoaded', () => {
	$('.es-gallery__loop').modaal({
    type: 'image'
  });

  $('.live-modaal').modaal({
    type: 'image'
  });
});


window.addEventListener('DOMContentLoaded', () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  anchorLinksArr.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth"
      });
    });
  });
});
class Select {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(function(){
      $('select').on('change', function(){
          if($(this).val() == "placeholder"){
              $(this).css('color','rgba(78, 93, 80, 0.4);')
          } else {
              $(this).css('color','#4E5D50')
          }
      });
  });
  }
}

class SimpleAccordion {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
      $(".js-simple-acc").on("click", function() {
      $(this).toggleClass("is-active");
      $(this).next().slideToggle();
    });
  }
}

class SwiperEvent {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    if($(".underSwiper .swiper-slide").length > 1) {
      var swiper = new Swiper(".underSwiper", {
        spaceBetween: 20,
        speed: 500,
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    }
    var swiper = new Swiper(".customer-Swiper", {
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
      slidesPerView: 1.2,
      centeredSlides: true,
      spaceBetween: 20,
      breakpoints: {
        // スライドの表示枚数：500px以上の場合
        960: {
          centeredSlides: false,
          slidesPerView: 1.7,
        }
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: function(index, className) {
          //中の文字を表示
          return '<span class="' + className + '">' + ['お問い合わせ','いえづくりについて','仮プラン提案・資金計画書','ローンシュミレーション','土地物件探し＆ご案内','住宅設備ショールーム','ベストプランのご提案','買付証明書の提出','土地のご契約','建物の契約',][index] + '</span>';　 }
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });

  }
}

class TagAccordion {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(this.el).on("click", function() {
      $(this).toggleClass("is-active");
      $(".js-tagAco-ele").slideToggle();
    });
  }
}

class WorksSwiper {
  constructor(el, opt){
    this.event();
  }
  event() {
    if (window.matchMedia('(max-width: 979px)').matches) {
      var swiper = new Swiper(".works-achieve-swiper", {
        autoplay: {
          delay: 2000,
        },
        speed: 1500,
        slidesPerView: 1.1,
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
      });
    }
  }
};
class Contact {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    // 郵便番号と住所のフィールドを取得
    const $zipField = $('#zip'); // 郵便番号の入力欄のID
    const $addressField = $('#address'); // 住所の入力欄のID

    // 要素が存在するか確認
    if ($zipField.length && $addressField.length) {
      // 郵便番号フィールドの入力イベントを監視
      $zipField.on('input', function () {
        const zip = $zipField.val().replace(/[^0-9]/g, ''); // 数字のみを抽出
        if (zip.length === 7) { // 日本の郵便番号は7桁
          $.ajax({
            url: `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip}`,
            type: 'GET',
            dataType: 'jsonp', // APIがJSONPを要求している場合
            success: function (data) {
              if (data.results) {
                const result = data.results[0];
                $addressField.val(`${result.address1} ${result.address2} ${result.address3}`);
              } else {
                $addressField.val('該当する住所が見つかりません');
              }
            },
            error: function () {
              console.error('郵便番号検索でエラーが発生しました');
              $addressField.val('エラーが発生しました');
            }
          });
        }
      });
    }

    let exhibition_hall = document.querySelectorAll("[name='来場展示場'] option");
    let exEl = $(".exhibition_hall");
    if(exhibition_hall) {
      exhibition_hall.forEach((ele) => {
        if(ele.value == this.getParam('exhibition_hall')) {
          ele.setAttribute('selected', 'selected');
          exEl.find('label').css({'display': 'none'})
          exEl.append($(`<div>${this.getParam('exhibition_hall')}</div>`));
          exEl.css({'display': 'flex', 'align-items': 'flex-end'});
        }
      })
    }

    let minDate;

    if($(".p-online-counter").length) {
      minDate = 5;
      console.log(minDate);
    } else {
      minDate = 2;
      console.log(minDate);
    }

    $('.datepicker').datepicker({
      dateFormat: 'yy/mm/dd',
      monthNames: [ "1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月" ],
      minDate: minDate
    }); 
  }
  getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}

// globals
class Hiraya {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    const swiper = new Swiper(".swiper-gallery01", {
      spaceBetween: 10,
      slidesPerView: 7,
      loop:true,

    });

    const swiper02 = new Swiper(".swiper-main01", {
      thumbs: {
        swiper: swiper
      },
      slidesPerView: 1,
      spaceBetween: 10,
    });

    const swiper03 = new Swiper(".swiper-gallery02", {
      spaceBetween: 10,
      slidesPerView: 7,
      loop:true,
    });

    const swiper04 = new Swiper(".swiper-main02", {
      thumbs: {
        swiper: swiper03
      },
      slidesPerView: 1,
      spaceBetween: 10,
    });

  }
}

class Top {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    var swiper = new Swiper(".mySwiper", {
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
      slidesPerView: 1.3,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
    });

    var swiper = new Swiper(".mySwiper_sp", {
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
    });
    var swiper = new Swiper(".newsTicker", {
      slidesPerView: 1,
      spaceBetween:15,
      direction: "vertical",
      autoplay: {
        delay: 3000,
      },
      speed: 1500,

      loop: true,
    });
    var swiper = new Swiper(".newsTicker_sp", {
      slidesPerView: 1,
      spaceBetween:15,
      directiona: "vertical",
      autoplay: {
        delay: 3000,
      },
      speed: 1500,

      loop: true,
    });



  }
}


//images resources loaded
$(window).on('load', () => {
  let top = new Top(".top");
  let hiraya = new Hiraya(".hiraya");
  let swiperContainer = new SwiperEvent(".swiperContainer");
  let inviewEvent = new InviewEvent('.js-inview');
  let select = new Select('select');
  let simpleAccordion = new SimpleAccordion('.js-simple-acc');
  let tagAccordion = new TagAccordion('.js-tagAco-taget');
  let header = new Header();
  let worksSwiper = new WorksSwiper('.works-achieve-swiper');
  let contact = new Contact('.p-contact');
  let headerScroll = new HeaderScroll('.js-header');
  let megaMenu = new MegaMenu('.js-megaMenu');
});


