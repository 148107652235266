class Contact {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    // 郵便番号と住所のフィールドを取得
    const $zipField = $('#zip'); // 郵便番号の入力欄のID
    const $addressField = $('#address'); // 住所の入力欄のID

    // 要素が存在するか確認
    if ($zipField.length && $addressField.length) {
      // 郵便番号フィールドの入力イベントを監視
      $zipField.on('input', function () {
        const zip = $zipField.val().replace(/[^0-9]/g, ''); // 数字のみを抽出
        if (zip.length === 7) { // 日本の郵便番号は7桁
          $.ajax({
            url: `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip}`,
            type: 'GET',
            dataType: 'jsonp', // APIがJSONPを要求している場合
            success: function (data) {
              if (data.results) {
                const result = data.results[0];
                $addressField.val(`${result.address1} ${result.address2} ${result.address3}`);
              } else {
                $addressField.val('該当する住所が見つかりません');
              }
            },
            error: function () {
              console.error('郵便番号検索でエラーが発生しました');
              $addressField.val('エラーが発生しました');
            }
          });
        }
      });
    }

    let exhibition_hall = document.querySelectorAll("[name='来場展示場'] option");
    let exEl = $(".exhibition_hall");
    if(exhibition_hall) {
      exhibition_hall.forEach((ele) => {
        if(ele.value == this.getParam('exhibition_hall')) {
          ele.setAttribute('selected', 'selected');
          exEl.find('label').css({'display': 'none'})
          exEl.append($(`<div>${this.getParam('exhibition_hall')}</div>`));
          exEl.css({'display': 'flex', 'align-items': 'flex-end'});
        }
      })
    }

    let minDate;

    if($(".p-online-counter").length) {
      minDate = 5;
      console.log(minDate);
    } else {
      minDate = 2;
      console.log(minDate);
    }

    $('.datepicker').datepicker({
      dateFormat: 'yy/mm/dd',
      monthNames: [ "1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月" ],
      minDate: minDate
    }); 
  }
  getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}
